<template>
  <div class="flex-grow-1">
   <UserTitle hideBack>
      <template v-slot:actions>
          <div>
            <h2 class="font-weight-black" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Saved Jobs</h2>
          </div>
      </template>
    </UserTitle>
    <!-- Filters -->
    <div>
      <v-row>
        <v-col cols="12" class="jobs-section"> 
          
          <preloader v-if="jobs && jobStatus.getting" />
          
          <custom-alert v-if="!sortJobs.length && !jobStatus.getting">
            No records found.
          </custom-alert>
          
          <div v-if="jobs">
            <!-- Job Card -->
            <JobsCard 
              v-for="job in sortJobs"
              :key="job.id"
              :job="job"
            />
            <!-- End Job Card -->
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JobsCard from '@/views/job/JobCard'
import _sortBy from 'lodash/sortBy'

export default {
  name: 'Jobs',

  metaInfo: {
    title: 'Jobs'
  },

    data() {
    return {
      order: 'desc',
      status: {
          deleting: false
      },
      orderBy: [
        {
          name: 'Newest First',
          value: 'desc'
        },
        {
          name: 'Oldest',
          value: 'asc'
        }
      ]
    }
  },

  components: {
    JobsCard,
  },

  computed: {
    ...mapState({
      jobs: state => state.favorite.favorites,
      jobStatus: state => state.favorite.status,
    }),

    sortJobs() {
      if (this.order == 'asc') {
        return _sortBy(this.jobs, 'createdAt')
      }
      else {
        return _sortBy(this.jobs, 'createdAt').reverse()
      }
    }
  },

  methods: {
    ...mapActions('favorite', [
        'getFavorites'
    ]),

  },
  
  created() {
    this.$store.dispatch('jobs/getFavorites')
    this.getFavorites()
  }
}
</script>